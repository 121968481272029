import React from 'react'
import styled from '@emotion/styled'
import mq from '../styles/mediaQueries'
import { Link, graphql } from 'gatsby'

import GreyPageTemplate from '../templates/grey-page'
import ArticleList from '../components/ArticleList'
import Seo from '../components/seo'

import { Headline2, Subheading2 } from '../components/Typography'

const Title = styled.h2`
  ${Headline2};
  color: var(--terminal-500);
  margin-block-start: 0;
  margin-block-end: 0;
`

const SubHeader = styled.div`
  ${Subheading2};
  color: var(--terminal-400);
  margin-top: 12px;
  ${mq({
    marginBottom: ['80px', '80px', '118px', '118px', '118px'],
  })};
`

const StyledLink = styled(Link)`
  background: #e9ebff;
  text-decoration: none;
`

class BlogIndex extends React.Component {
  render() {
    const pageTitle = 'Blog'
    const { data } = this.props
    const posts = data.allMarkdownRemark.edges

    return (
      <GreyPageTemplate title={pageTitle} copyrightYear={'2022'}>
        <Seo
          title={pageTitle}
          description="Follow the blog to receive the latest news on Super Good Software and solidus development all written by the team at Super Good. "
          keywords={[
            `Ruby on Rails`,
            `Rails`,
            `React`,
            `Spree`,
            `Solidus`,
            `eCommerce`,
          ]}
        />
        <Title>More than the gist</Title>
        <SubHeader>
          Advice, insights and announcements from the Super Good team. We write
          articles about <StyledLink to="/blog/solidus">Solidus</StyledLink>,{' '}
          <StyledLink to="/blog/ruby">Ruby</StyledLink>,{' '}
          <StyledLink to="/blog/rails">Rails</StyledLink> and{' '}
          <StyledLink to="/blog/software-development">
            software development
          </StyledLink>{' '}
          in general.
        </SubHeader>
        <ArticleList posts={posts} />
      </GreyPageTemplate>
    )
  }
}

export default BlogIndex

export const pageQuery = graphql`
  query {
    site {
      siteMetadata {
        title
      }
    }
    allMarkdownRemark(
      filter: {
        fileAbsolutePath: { glob: "**/content/blog/*/*.md" }
        frontmatter: { draft: { ne: true } }
      }
      sort: { fields: [frontmatter___date], order: DESC }
    ) {
      edges {
        node {
          fields {
            slug
          }
          timeToRead
          frontmatter {
            date(formatString: "MMMM DD, YYYY")
            title
            category
            author
          }
        }
      }
    }
  }
`
